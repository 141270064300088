import React, { useRef, useState } from 'react';
import ContentTeaser from '../components/content/ContentTeaser';
import ContentSlider from '../components/content/ContentSlider';
import PageHeader from '../components/content/PageHeader';
import Kpi from '../components/content/Kpi';
import Layout from '../modules/Layout';
import Text from '../components/content/Text';
import Modal from '../components/ui/Modal';
import Form from '../components/content/Form';
import useBodyScrollLock from '../hooks/useBodyScrollLock';
import FamilyCheck from '../components/content/FamilyCheck';

const HomePage = () => {
  const [isFormActive, setIsFormActive] = useState(false);
  const [isFormSubitted, setIsFormSubmitted] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  useBodyScrollLock({
    targetElement: containerRef,
    isLocked: isFormActive,
  });

  const beruf = {
    desktopImage: 'berufsunfaehigkeit_desk',
    mobileImage: 'berufsunfaehigkeit_mob',
    badge1Line1: 'auf einmal mehr auf',
    badge1Line2: 'euren Schultern lastet,',
    badge2Line1: 'muss das Familien-',
    badge2Line2: 'einkommen abgesichert sein.',
    title: 'VGH Berufsunfähigkeitsschutz',
    headline: 'Damit Sie auch auskommen, wenn ein Einkommen wegfällt',
    text: 'Sie sind beide berufstätig und die Kohle stimmt? Doch kommen Sie auch noch klar, falls einer von Ihnen plötzlich aufgrund von Krankheit, Unfall oder Burnout berufsunfähig wird? Nehmen Sie das nicht auf die leichte Schulter. Ein Berufsunfähigkeitsschutz sorgt dafür, dass Sie im Fall der Fälle finanziell auf der sicheren Seite sind.',
    points: [
      {
        text: 'Je jünger Sie starten, desto günstiger ist der Beitrag',
      },
      {
        text: 'Monatliche Rentenzahlung schon ab 50 % Berufsunfähigkeit',
      },
    ],
    btnText: 'Termin vereinbaren',
    btnTarget: () => setIsFormActive(true),
  };

  const risiko = {
    desktopImage: 'risikoleben_desk',
    mobileImage: 'risikoleben_mob',
    badge1Line1: 'Familie das Wichtigste in',
    badge1Line2: 'deinem Leben ist,',
    badge2Line1: 'sichere sie auch für',
    badge2Line2: 'deinen Todesfall ab.',
    title: 'Risikolebensversicherung',
    headline: 'Sollte das Leben andere Pläne haben als Sie',
    text: 'Eltern sein heißt, für seine Kids da zu sein. Jeden Tag, ein Leben lang. Und das dauert hoffentlich ewig. Trotzdem: Mit einer Risikolebensversicherung können Sie finanziell auch noch für die Familie da sein, falls Sie nicht mehr da sein sollten. Vor allem, wenn Sie noch Kredite laufen haben: besser nicht ohne!',
    points: [
      {
        text: 'Finanzielle Absicherung der Familie im Todesfall',
      },
      {
        text: 'Absicherung von langfristigen Zahlungsverpflichtungen',
      },
    ],
    btnText: 'Termin vereinbaren',
    btnTarget: () => setIsFormActive(true),
  };

  const haftpflicht = {
    desktopImage: 'haftpflicht_desk',
    mobileImage: 'haftpflicht_mob',
    badge1Line1: 'Nachbars Scheibe',
    badge1Line2: 'zu Bruch geht,',
    badge2Line1: 'sollte das gute Verhältnis',
    badge2Line2: 'keinen Schaden nehmen.',
    title: 'Privat-Haftpflichtversicherung',
    headline: 'Erwachsen werden soll schließlich Spaß machen',
    text: 'Sie haben ganz entspannte Nachbarn, die Kinder lieben. Aber schon mal darüber nachgedacht, wie entspannt sie reagieren, wenn ihr Eigentum zerdeppert wird? Ersparen Sie sich den Ärger. Eine Privathaftpflicht beruhigt die Nachbarn – und Sie auch.',
    points: [
      {
        text: 'Individueller und bedarfsgerechter Versicherungsschutz bis 50 Mio. €',
      },
      {
        text: 'Wir prüfen, ob und in welcher Höhe Sie für einen Schaden haften und wehren unberechtigte Ansprüche ggf. auch vor Gericht ab',
      },
    ],
    btnText: 'Termin vereinbaren',
    btnTarget: () => setIsFormActive(true),
  };

  const unfall = {
    desktopImage: 'unfall_desk',
    mobileImage: 'unfall_mob',
    badge1Line1: 'Kinder die Welt',
    badge1Line2: 'entdecken,',
    badge2Line1: 'geben wir Rückhalt.',
    title: 'VGH Unfallschutz',
    headline: 'Weil ein kleiner Sturz große Folgen haben kann',
    text: 'Auf Bäume klettern, Radfahren … Kids lieben es, die Welt zu entdecken. Aber was, wenn dabei etwas schief geht? Klar beschützen Sie Ihre Kinder 24/7. Aber Sie sind nun mal keine Superhelden. Sie sind super, so wie Sie sind und machen, was Sie können. Zum Beispiel: Ihre Familie ausreichend für den Fall eines Unfalls absichern.',
    points: [
      {
        text: 'Weltweiter Schutz, rund um die Uhr – in Beruf und Freizeit',
      },
      {
        text: 'Kapitalzahlung, wenn nach einem Unfall körperliche Beeinträchtigungen zurückbleiben',
      },
      {
        text: 'Das Extra: Ihr persönlicher Leistungslotse bzw. LeistungslotsePlus',
      },
    ],
    btnText: 'Termin vereinbaren',
    btnTarget: () => setIsFormActive(true),
  };

  const contentTeaserItems = [beruf, risiko, haftpflicht, unfall];

  const sliderItems = [
    {
      image: 'hausrat',
      title: 'Hausratversicherung',
      headline: 'Falls Ihr Zuhause mal nasse Füße kriegt',
      text: 'Ein gemeinsames Zuhause hat schon was. Und ist gar nicht so spießig, wie Sie vor ein paar Jahren noch dachten, oder? Ganz im Gegenteil: Es ist richtig schön. Das soll auch so bleiben – selbst wenn mal ein Rohr platzt, es brennt oder eingebrochen wird etc.',
      btnText: 'Termin vereinbaren',
      btnTarget: () => setIsFormActive(true),
    },
    {
      image: 'rechtsschutz',
      title: 'Rechtsschutzversicherung',
      headline: 'Damit Sie nicht blechen müssen, wenn Konflikte eskalieren',
      text: 'Ihr kleiner Racker wird zum Rocker? Yeah! Nur leider stehen Ihre Nachbarn mehr auf leise Töne und Klassik? Gar nicht gut. Denn ein kleiner Konflikt kann schnell eskalieren und zum teuren Rechtsstreit werden – und den braucht niemand.',
      btnText: 'Termin vereinbaren',
      btnTarget: () => setIsFormActive(true),
    },
    {
      image: 'fahrrad',
      title: 'VGH Fahrradversicherung',
      headline: 'Bevor gutes Rad teuer wird',
      text: 'Einkaufen, die Kleinen in die Kita bringen, zur Arbeit fahren: Sie machen fast alles mit dem Bike? Für Ihre Gesundheit und die Umwelt. Klasse! Dann haben Sie sicher schon eine Fahrradversicherung. Nicht? Besser wär’s.',
      btnText: 'Termin vereinbaren',
      btnTarget: () => setIsFormActive(true),
    },
    {
      image: 'tierhalter',
      title: 'Tierhalter-Haftpflichtversicherung',
      headline: 'Sollte Ihr Hund mal Ärger machen, haften nämlich Sie',
      text: 'Familie ohne Hund geht gar nicht? Finden wir auch. Haustiere sind toll. Außerdem lernen die Kids durch sie schon früh, Verantwortung zu übernehmen. Sie aber bitte auch: Denn eine Haftpflicht für Hunde ist in Niedersachsen Pflicht!',
      btnText: 'Termin vereinbaren',
      btnTarget: () => setIsFormActive(true),
    },
    {
      image: 'cyber',
      title: 'VGH CyberSchutz',
      headline: 'Da Hacker auch vor Ihnen und Ihren Kids nicht Halt machen',
      text: 'Wann waren Sie das letzte Mal online? Gestern? Vor einer Stunde? Oder vor einer Minute? Hacker sind immer online und interessieren sich auch für Ihre Klicks. Unser CyberSchutz schützt Sie z. B. bei Online-Einkäufen – und Ihre Kinder bei Cyber-Mobbing.',
      btnText: 'Termin vereinbaren',
      btnTarget: () => setIsFormActive(true),
    },
    {
      image: 'kfz',
      title: 'Kfz-Versicherung',
      headline: 'Wer viel unterwegs ist, sollte nicht nur auf sein Auto vertrauen',
      text: 'Logisch haben Sie eine Kfz-Versicherung, wenn Sie ein Auto haben. Aber schon mal darüber nachgedacht, zu einer Autoversicherung zu wechseln, auf die Sie sich wirklich verlassen können? Als junge Familie auf einem Schaden sitzen zu bleiben, ist schließlich kein Spaß.',
      btnText: 'Termin vereinbaren',
      btnTarget: () => setIsFormActive(true),
    },
    {
      image: 'bav',
      title: 'Betriebliche Altersvorsorge',
      headline: 'Weil sich eine Zusatzrente zum halben Preis schon jetzt auszahlt',
      text: 'Durch Steuer- und Sozialabgabenersparnis sowie den Zuschuss von Ihrem Arbeitgeber zahlen Sie nur etwa die Hälfte der Beiträge selbst. Hört sich gut an? Ist es auch. Also, heute schon an morgen denken – dann wird es später auch nicht eng.',
      btnText: 'Termin vereinbaren',
      btnTarget: () => setIsFormActive(true),
    },
    {
      image: 'krankenzusatz',
      title: 'Kranken-Zusatzversicherungen',
      headline: 'Die Gesetzliche zahlt leider nicht alles ',
      text: 'Schon mal überlegt, was alles an Kosten auf Sie zukommen kann – allein schon durch eine Brille, die Behandlungen der Zähne oder durch eine Nacht mit den Kids im Krankenhaus? Die gesetzliche Krankenversicherung zahlt nicht immer alles, was sinnvoll ist. Mit uns schließen Sie diese Lücke.',
      btnText: 'Termin vereinbaren',
      btnTarget: () => setIsFormActive(true),
    },
  ];

  const KpiItems = [
    {
      title: 'Weil Sie jemanden brauchen, der <strong>da</strong> ist:<br/>Über',
      amount: '270',
      text: 'Jahre individuelle und kompetente Beratung in Niedersachsen und Bremen sagen eigentlich alles.',
    },
    {
      title: 'Weil Sie jemanden brauchen, der <strong>fair</strong> ist:<br/>Etwa',
      amount: '1,8 Mio.',
      text: 'Kunden sagen das Gleiche – genauso wie jede Menge Auszeichnungen von Focus Money bis hin zu Stiftung Warentest.',
    },
    {
      title: 'Weil Sie jemanden brauchen, der <strong>nah</strong> ist:<br/>Rund',
      amount: '1.100',
      text: 'Vertretungen und Sparkassen, die Sie persönlich vor Ort beraten statt anonym online.',
    },
  ];

  return (
    <div ref={containerRef}>
      <Layout
        pageName="IIIᐅ Familienversicherung | VGH"
        description="VGH Versicherungscheck online ✅ Welche Versicherungen brauchen Familien? ✅ Wie schützen Sie Ihre Familie am besten? ᐅ Jetzt informieren! "
      >
        <PageHeader
          desktopImage="header"
          mobileImage="header_mob"
          badge1Line1="gerade alles wichtiger"
          badge1Line2="als Versicherung ist,"
          badge2Line1="seid ihr bei uns"
          badge2Line2="in besten Händen."
        />
        <Text
          textAboveHeadline="Die VGH Familienversicherungen"
          headline="Weil Familie sein, Verantwortung und Vorsorge bedeutet"
          headlineLevel={1}
          text="<p>Wow – gefühlt war‘s doch erst gestern, als Sie vor Freude über das erste Ultraschallbild ausgeflippt sind. Und plötzlich sind Sie Familie! Das Schönste, was es gibt. Aber auch das Verantwortungsvollste. Denn Eltern sein, heißt nicht nur Liebe geben, sondern auch Sicherheit. Heute, morgen und in Zukunft.<br/><br/>Wenn Sie wollen, helfen wir Ihnen dabei. Als erfahrene Versicherer kennen wir uns mit jeder Lebenssituation aus und wissen, wie wichtig Versicherungen für junge Familien sind. Ganz egal, ob Sie schon Kinder haben oder noch in der „Planungsphase“ sind.</p>"
          btnText="Termin vereinbaren"
          btnTarget={() => setIsFormActive(true)}
        />
        <FamilyCheck
          headline="Wie viel Sicherheit braucht Ihre Familie?"
          text="Klar wissen Sie, dass Sicherheit absolut wichtig ist und Versicherungen
      sein müssen. Aber welche? Und vor allem, welche passen wirklich zu Ihnen
      und Ihrer Lebenssituation? Klicken Sie sich einfach kurz durch unseren Familien-Check. Wir zeigen Ihnen dann im
      Anschluss direkt die Versicherungen, die Sie auf jeden Fall haben sollten."
          btnTarget={() => setIsFormActive(true)}
          btnText="Termin vereinbaren"
        />
        <ContentTeaser
          headline="Unsere stärksten Versicherungen für Sie und die Kids"
          text="Diese vier sollten junge Familien auf jeden Fall auf dem Schirm haben. Sie sind die wichtigsten Basics, um sehr gut abgesichert in die Zukunft zu starten. Stiftung Warentest empfiehlt diese vier VGH Versicherungen ebenfalls."
          items={contentTeaserItems}
        />
        <Text
          headline="Wenn Sie für Ihre Familie auf Nummer sicher gehen wollen"
          text="Noch mehr Versicherungen? Reichen nicht zwei oder drei? Das entscheiden natürlich Sie und hängt zudem von Ihrer Lebenssituation ab. Aber schauen Sie einfach mal durch. Wir haben für alle Ansprüche die passende Versicherung – und passen uns auch Ihren individuellen Wünschen an."
        />
        <ContentSlider items={sliderItems} />
        <Kpi
          headline="Warum VGH?"
          kpiItems={KpiItems}
          btnTarget={() => setIsFormActive(true)}
          btnText="Termin vereinbaren"
        />
        {isFormActive && (
          <Modal
            headline={!isFormSubitted ? 'Beratungstermin vereinbaren' : ''}
            closeModal={() => {
              setIsFormActive(false);
            }}
          >
            <Form
              closeModal={() => {
                setIsFormActive(false);
              }}
              formIsSubmitted={val => setIsFormSubmitted(val)}
            />
          </Modal>
        )}
      </Layout>
    </div>
  );
};

export default HomePage;
