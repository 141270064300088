import React, { ReactNode } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import SEO from '../Seo';

type LayoutProps = {
  children: ReactNode;
  pageName: string;
  description: string;
};

type SiteSettings = {
  siteName: string;
  siteAuthor: string;
  ogImage: string;
};

const Layout = ({ children, pageName, description }: LayoutProps) => {
  const siteSettings: SiteSettings = {
    siteName: 'Die VGH Familienversicherungen',
    siteAuthor: 'VGH Versicherungen',
    ogImage: `${process.env.DOMAIN}/ogimage.jpg`,
  };

  return (
    <>
      <SEO
        title={`${siteSettings.siteName} - ${pageName}`}
        description={description}
        author={siteSettings.siteAuthor}
      />
      {/* <Header /> */}
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
