import React from 'react';
import SliderItem from './SliderItem/index';
import * as styles from './styles.module.scss';

export type ContentSliderItem = {
  image: string;
  title: string;
  label?: string;
  headline: string;
  text: string;
  btnText: string;
  btnTarget: () => void;
};

type ContentSliderProps = {
  items: ContentSliderItem[];
};

const ContentSlider = ({ items = [] }: ContentSliderProps) => (
  <section className={styles.container}>
    <div className={styles.sliderWrap}>
      {items?.map((item, index) => (
        <SliderItem item={item} key={index} />
      ))}
    </div>
  </section>
);

export default ContentSlider;
