import React, { ReactChild, ReactNode } from 'react';
import Headline from '../Headline';
import Icon from '../Icon';

import * as styles from './styles.module.scss';

type ModalProps = {
  closeModal?: VoidFunction;
  children: ReactNode;
  headline?: ReactChild;
};

const Modal = ({ headline, children, closeModal = () => {} }: ModalProps) => (
  <>
    <div aria-hidden className={styles.darkBG} onClick={closeModal} />
    <div className={styles.wrapper}>
      <div className={styles.modal}>
        {headline && (
          <Headline level={2} additionalClass={styles.headline}>
            {headline}
          </Headline>
        )}
        <div role="button" aria-hidden className={styles.closeBtn} onClick={closeModal}>
          <span style={{ marginBottom: '-3px' }}>
            <Icon name="close" additionalClass={styles.closeIcon} width={20} />
          </span>
        </div>
        <div className={styles.modalContent}>{children}</div>
      </div>
    </div>
  </>
);

export default Modal;
