import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useLocation } from '@reach/router';

import Checkbox from '../../ui/CheckboxForm';
import Headline from '../../ui/Headline';
import Input from '../../ui/Input';
import Icon from '../../ui/Icon';
import Textarea from '../../ui/Textarea';
import useSparkasse from '../../../hooks/useSparkasse';
import * as styles from './styles.module.scss';

type FormProps = {
  closeModal?: VoidFunction;
  formIsSubmitted: (val: boolean) => void;
};

const Form = ({ closeModal, formIsSubmitted }: FormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isPending, setIsPending] = useState(false);
  const [mailTo, setMailTo] = useState('service@vgh.de');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const location = useLocation();
  const sparkasse = useSparkasse();

  useEffect(() => {
    const aid = location.search.toLocaleLowerCase().split('aid=');
    const mailToTmp = aid[1] ? sparkasse.find(item => item.aid === aid[1]).email : 'service@vgh.de';
    setMailTo(mailToTmp);
  }, []);

  const onSubmit = async (data: any) => {
    setIsPending(true);
    const formData = {
      ...data,
      mailTo: mailTo,
    };
    try {
      const res = await axios.post(`${process.env.API_URL ?? ''}`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setIsSubmitted(true);
      formIsSubmitted(true);
    } catch (error: any) {
      console.log(error.message);
    }
    setIsPending(false);
  };

  if (isSubmitted) {
    return (
      <div className="mt-55 flex flex-col items-center">
        <Icon name="success" width={90} />
        <Headline level={2} centered additionalClass="mt-30">
          Vielen Dank!
        </Headline>
        <p className="text-center text-gray text-12">Wir werden uns in Kürze bei Ihnen melden.</p>
        <button type="button" onClick={closeModal} className={styles.btn}>
          schließen
        </button>
      </div>
    );
  }

  return (
    <section className={styles.wrapper} id="anmelden">
      <p className={styles.text}>Meine persönlichen Angaben</p>
      <form id="form" className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" name="target_uri" value="./danke.html" />
        <input type="hidden" name="error_target_uri" value="./fehler.html" />

        <div className={styles.salutationWrapper}>
          <div className="flex items-center">
            <div className="mr-20 inline-flex items-center">
              <label className="mr-20 inline-block">Anrede</label>
              <input type="radio" id="frau" name="anrede" value="Frau" className="mr-1" {...register('anrede')} />
              <label htmlFor="frau">Frau</label>
            </div>

            <div className="mr-20 inline-flex items-center">
              <input type="radio" id="hueherry" name="anrede" value="Herr" className="mr-1" {...register('anrede')} />
              <label htmlFor="herr">Herr</label>
            </div>
          </div>
          <span>* Pflichtfeld</span>
        </div>
        <div className={styles.inputWrapper}>
          <Input
            name="name"
            type="text"
            label="Vorname, Name*"
            placeholder="Ihr Vorname, Name …"
            autoComplete="name"
            className="mt-30 phoneLandscape:mt-0"
            register={register('name', {
              validate: (value: string) => {
                return value.trim().length > 0;
              },
            })}
            error={errors.name}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Input
            name="email"
            type="email"
            label="E-Mail Adresse*"
            placeholder="E-Mail Adresse …"
            autoComplete="email"
            register={register('email', {
              validate: (value: string) => {
                return value.trim().length > 0;
              },
            })}
            error={errors.email}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Input
            name="street"
            type="text"
            label="Straße, Hausnummer"
            placeholder="Straße, Hausnummer …"
            autoComplete="street"
            register={register('street', {})}
            error={errors.street}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Input
            name="zip"
            type="text"
            label="PLZ, Ort"
            placeholder="PLZ, Ort …"
            autoComplete="city"
            register={register('zip', {})}
            error={errors.postalcode}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Textarea
            name="message"
            label="Ihre Nachricht*"
            placeholder="Ihre Nachricht …"
            autoComplete="message"
            register={register('message', {
              validate: (value: string) => {
                return value.trim().length > 0;
              },
            })}
            error={errors.postalcode}
          />
        </div>
        <div className={styles.checkboxWrapper}>
          <p className={styles.text}>
            Ihre Daten werden temporär von den VGH Versicherungen zwischengespeichert, um diese Nachricht an Ihre
            Sparkasse weiterzuleiten. Dabei gelten die{' '}
            <a href="https://www.vgh.de/content/datenschutz/" target="_blank">
              Datenschutzbestimmungen der VGH
            </a>
            . Die weitere Nutzung Ihrer Daten ist durch die Datenschutzbestimmungen Ihrer Sparkasse geregelt.
          </p>

          <Checkbox
            name="datacheck"
            register={register('Datenschutzbestimmungen akzeptiert', {
              validate: (value: boolean) => {
                return value;
              },
            })}
            error={errors.datacheck}
          >
            <p>Ich habe die Information zur Verwendung meiner Daten zur Kenntnis genommen *</p>
          </Checkbox>
        </div>
        <button type="submit" className={styles.btn} disabled={isPending}>
          Nachricht abschicken
        </button>
      </form>
    </section>
  );
};

export default Form;
