import React from 'react';
import Headline from '../../ui/Headline';
import Container from '../../ui/Grid/Container';
import Row from '../../ui/Grid/Row';
import Col from '../../ui/Grid/Col';
import * as styles from './styles.module.scss';

type TextProps = {
  headline: string;
  headlineLevel?: 1 | 2;
  textAboveHeadline?: string;
  text: string;
  btnText?: string;
  btnTarget?: () => void;
};

const Text = ({ headline, textAboveHeadline, text, btnText, btnTarget, headlineLevel = 2 }: TextProps) => {
  return (
    <Container additionalClass={styles.container}>
      <Row>
        <Col mobile={12} tablet={10} tabletLandscape={10} desktop={6} centered>
          {textAboveHeadline && (
            <div
              dangerouslySetInnerHTML={{
                __html: textAboveHeadline,
              }}
              className={styles.textAboveHeadline}
            ></div>
          )}
          <Headline additionalClass={styles.heading} classLevel={1} level={headlineLevel}>
            {headline}
          </Headline>
          <div
            dangerouslySetInnerHTML={{
              __html: text,
            }}
            className={styles.para}
          ></div>
          {btnText && (
            <div className={styles.btnWrapper}>
              <button onClick={btnTarget} type="button" className={styles.btn}>
                {btnText}
              </button>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Text;
