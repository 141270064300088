import classNames from 'classnames';
import React, { forwardRef } from 'react';
import { FieldError } from 'react-hook-form';
import * as styles from './styles.module.scss';

type Props = {
  autoComplete?: string;
  className?: string;
  defaultValue?: string;
  error?: FieldError;
  label?: string;
  name: string;
  placeholder?: string;
  register?: any;
  required?: boolean;
};

const Textarea: React.ForwardRefExoticComponent<Props> = forwardRef(
  (
    {
      autoComplete = '',
      className = '',
      defaultValue = '',
      error,
      label,
      name,
      placeholder,
      register = {},
      required = false,
    },
    ref
  ) => (
    <div className={className}>
      {label && (
        <label htmlFor={name}>
          <p className={styles.label}>{label}</p>
        </label>
      )}
      <textarea
        autoComplete={autoComplete}
        className={classNames(styles.inputStyle, { [styles.error]: error })}
        id={name}
        name={name}
        placeholder={placeholder}
        ref={ref}
        required={required}
        tabIndex={0}
        defaultValue={defaultValue}
        {...register}
      ></textarea>
    </div>
  )
);

export default Textarea;
