import React, { useContext, useEffect, useState } from 'react';

import { FamilyCheckContext } from '../../../../../providers';
import Button from '../../../../ui/Button';
import Card from '../../../../ui/Card';
import Container from '../../../../ui/Grid/Container';
import Icon from '../../../../ui/Icon';
import insurances from '../../../../../assets/data/insurances.json';
import questions from '../../../../../assets/data/questions.json';
import * as styles from './styles.module.scss';

type Props = {
  onRestart: VoidFunction;
  btnTarget: () => void;
  btnText: string;
};

const CheckResult = ({ onRestart, btnTarget, btnText }: Props) => {
  const { answerData, restart, currentQuestion } = useContext(FamilyCheckContext);
  const [resultInsurances, setResultInsurances] = useState<any[]>([]);
  let prev = [...insurances];

  useEffect(() => {
    if (currentQuestion === 0) {
      prev = [...insurances];
      setResultInsurances([]);
    }
  }, [currentQuestion]);

  useEffect(() => {
    answerData.forEach((answers: any, questionIndex: number) => {
      if (answers) {
        answers.forEach((answer: any) => {
          questions[questionIndex].answers[answer.index].points?.forEach(point => {
            const insuranceId = prev.findIndex(item => item.id === point.id);
            prev[insuranceId].points += point.points;
          });
          if (answer.children) {
            answer.children.forEach((child: any) => {
              questions[questionIndex].answers[answer.index].childAnswers[child.index].points?.forEach((point: any) => {
                const insuranceId = prev.findIndex(item => item.id === point.id);
                prev[insuranceId].points += point.points;
              });
            });
          }
        });
      }
    });
    setResultInsurances(prev);
  }, [answerData, currentQuestion]);

  const clickRestart = () => {
    restart();
    onRestart();
  };

  return (
    <Container additionalClass={styles.contentWrapper}>
      <Card className={styles.card}>
        <p className={styles.title}>{'Check!'}</p>
        <p className={styles.content}>
          {
            'Vielen Dank für Ihre Teilnahme! Das lief doch super – und hier kommt Ihr Ergebnis. Diese vier wichtigen Basic-Versicherungen sollten auch in Ihrer Familie auf keinen Fall fehlen:'
          }
        </p>
        <div className={styles.insuranceWrapper}>
          {resultInsurances.slice(0, 4).map(item => {
            return (
              <a key={item.id} href={item.link} className={styles.insurance} target="_blank" rel="noreferrer">
                <Icon name="arrow" width={12} height={12} />
                <p>{item.name}</p>
              </a>
            );
          })}
        </div>
        {resultInsurances.slice(4).filter(item => item.points > 0).length > 0 && (
          <p className={styles.content}>
            {
              'Außerdem ergaben Ihre ausgefüllten Antworten, dass folgende Versicherungen genau zu Ihrer aktuellen Lebenssituation passen und Ihren individuellen Versicherungsschutz optimal abrunden:'
            }
          </p>
        )}
        <div className={styles.insuranceWrapper}>
          {resultInsurances
            .slice(4)
            .filter(item => item.points > 0)
            .sort((a, b) => b.points - a.points)
            .map(item => (
              <a key={item.id} href={item.link} className={styles.insurance} target="_blank" rel="noreferrer">
                <Icon name="arrow" width={12} height={12} />
                <p>{item.name}</p>
              </a>
            ))}
        </div>
        <p className={styles.content}>
          {
            'Egal, ob Sie die ein oder andere Versicherung bereits abgeschlossen haben, ein Beratungstermin lohnt sich immer. In diesem können wir ganz gezielt auf Ihre persönliche Situation eingehen und gemeinsam für Sie den besten Versicherungsschutz rausholen.'
          }
        </p>
        <div className={styles.buttonWrapper}>
          <Button label={btnText} onClick={btnTarget} />
          <Button label="Neustarten" onClick={() => clickRestart()} color="secondary" />
        </div>
      </Card>
    </Container>
  );
};

export default CheckResult;
