import React from 'react';
import c from 'classnames';
import * as styles from './styles.module.scss';

type BadgeProps = {
  headline: string;
  position?: 'top' | 'middle' | 'bottom';
  firstHeadline: string;
  secondHeadline?: string;
  size?: 'small' | 'big';
};

const Badge = ({ headline, firstHeadline, secondHeadline, position, size = 'small' }: BadgeProps) => {
  return (
    <div
      className={c({
        [styles.top]: position === 'top',
        [styles.middle]: position === 'middle',
        [styles.bottom]: position === 'bottom',
        [styles.big]: size === 'big',
      })}
    >
      {headline && <p className={styles.headline}>{headline}</p>}
      {firstHeadline && <p className={styles.subline}>{firstHeadline}</p>}
      {secondHeadline && <p className={styles.subline}>{secondHeadline}</p>}
    </div>
  );
};

export default Badge;
