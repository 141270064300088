import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { FieldError } from 'react-hook-form';

import * as styles from './styles.module.scss';

type Props = {
  children?: React.ReactNode;
  disabled?: boolean;
  error?: FieldError;
  name: string;
  register?: any;
  onclick?: React.MouseEventHandler<HTMLButtonElement>;
};

const Checkbox = forwardRef(({ children, error, name, register = {}, disabled, onclick }: Props, ref) => {
  return (
    <div className={styles.wrapper}>
      <input
        id={name}
        name={name}
        type="checkbox"
        ref={ref}
        className={classNames(styles.checkbox, { [styles.error]: error })}
        onClick={onclick}
        disabled={disabled}
        {...register}
      />
      <label htmlFor={name} className={styles.label}>
        {children}
      </label>
    </div>
  );
});

export default Checkbox;
