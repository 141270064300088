const useSparkasse = () => {
  function spkItem(_aid,_name,_hp,_email,_blz){
    this.aid = _aid;
    this.name = _name;
    this.email = _email;
    this.hp = _hp;
    this.blz = _blz;
  }
  
  const spk = new Array();
  
  spk.push(new spkItem('1501','Stadtsparkasse Barsinghausen','http://www.stadtsparkasse-barsinghausen.de','postfach@ssk-barsinghausen.de'));
  
  spk.push(new spkItem('1502','Kreissparkasse Bersenbrueck','http://www.ksk-bersenbrueck.de','info@ksk-bersenbrueck.de'));
  
  spk.push(new spkItem('1504','Stadtsparkasse Burgdorf','http://www.stadtsparkasse-burgdorf.de','info@stadtsparkasse-burgdorf.de'));
  
  spk.push(new spkItem('1507','Sparkasse Cuxhaven','http://www.ssk-cuxhaven.de','Stadtsparkasse_Cuxhaven@vgh.de'));
  
  spk.push(new spkItem('1508','Kreissparkasse Diepholz','http://www.kreissparkasse-diepholz.de','mail@kreissparkasse-diepholz.de'));
  
  spk.push(new spkItem('1509','Sparkasse Duderstadt','http://www.sparkasse-duderstadt.de','Sparkasse_Duderstadt@vgh.de'));
  
  spk.push(new spkItem('1510','Sparkasse Einbeck','http://www.sparkasse-einbeck.de','Spkein@sparkasse-einbeck.de'));
  
  spk.push(new spkItem('1511','Sparkasse Emden','http://www.sparkasse-emden.de','info@spk-emden.de'));
  
  spk.push(new spkItem('1512','Sparkasse Emsland','http://www.sparkasse-emsland.de','info@sparkasse-emsland.de'));
  
  spk.push(new spkItem('1513','Sparkasse Celle-Gifhorn-Wolfsburg','http://www.sparkasse-cgw.de','info@sparkasse-cgw.de'));
  
  spk.push(new spkItem('1514','Sparkasse Goettingen','http://www.spk-goettingen.de','info@spk-goettingen.de'));
  
  spk.push(new spkItem('1518','Sparkasse Hannover','http://www.sparkasse-hannover.de','info@fs-hannover.de'));
  
  spk.push(new spkItem('1519','Sparkasse Harburg-Buxtehude','http://www.sparkasse-harburg-buxtehude.de','vgh@sparkasse-harburg-buxtehude.de'));
  
  spk.push(new spkItem('1520','Sparkasse Osterode','http://www.sparkasse-osterode.de','service@sparkasse-osterode.de'));
  
  spk.push(new spkItem('1521','Sparkasse Hildesheim Goslar Peine','http://www.sparkasse-hgp.de','abt.versicherungen@sparkasse-hgp.de'));
  
  spk.push(new spkItem('1523','Sparkasse LeerWittmund','http://sparkasse-leerwittmund.de','info@sparkasse-leerwittmund.de'));
  
  spk.push(new spkItem('1525','Sparkasse Lueneburg','http://www.sparkasse-lueneburg.de','info@sparkasse-lueneburg.de'));
  
  spk.push(new spkItem('1526','Kreissparkasse Melle','http://www.ksk-melle.de','Krspk_Melle@vgh.de'));
  
  spk.push(new spkItem('1527','Sparkasse Muenden','http://www.spk-muenden.de','vgh@spk-muenden.de'));
  
  spk.push(new spkItem('1528','Sparkasse Nienburg','http://www.sparkasse-nienburg.de','Sparkasse_Nienburg-Weser@vgh.de'));
  
  spk.push(new spkItem('1529','Sparkasse Aurich-Norden','http://www.sparkasse-aurich-norden.de','info@sparkasse-aurich-norden.de'));
  
  spk.push(new spkItem('1530','Sparkasse Nordhorn','http://www.sparkasse-nordhorn.de','Info@sparkasse-nordhorn.de'));
  
  spk.push(new spkItem('1531','Kreissparkasse Northeim','http://www.ksn-northeim.de','KSN@KSN-Northeim.de'));
  
  spk.push(new spkItem('1533','Sparkasse Osnabrueck','http://www.sparkasse-osnabrueck.de','info@sparkasse-osnabrueck.de'));
  
  spk.push(new spkItem('1537','Stadtsparkasse Bad Pyrmont','http://www.ssk-bad-pyrmont.de','post@ssk-bad-pyrmont.de'));
  
  spk.push(new spkItem('1538','Sparkasse Rotenburg-Osterholz','http://www.spk-row-ohz.de','info@spk-row-ohz.de'));
  
  spk.push(new spkItem('1541','Sparkasse Schaumburg','http://www.spk-schaumburg.de','versicherungen@spk-schaumburg.de'));
  
  spk.push(new spkItem('1542','Sparkasse Scheessel','http://www.sparkasse-scheessel.de','Christian.Sackmann@spk-scheessel.de'));
  
  spk.push(new spkItem('1543','Kreissparkasse Soltau','http://www.ksk-soltau.de','service@ksk-soltau.de'));
  
  spk.push(new spkItem('1544','Kreissparkasse Stade','http://www.ksk-stade.de','info@ksk-stade.de'));
  
  spk.push(new spkItem('1545','Sparkasse Stade-Altes Land','http://www.sparkasse-stade-altes-land.de','info@spk-sal.de'));
  
  spk.push(new spkItem('1546','Kreissparkasse Syke','http://www.ksk-syke.de','Versicherungen@ksk-syke.de'));
  
  spk.push(new spkItem('1547','Sparkasse Uelzen-Luechow-Dannenberg','http://www.sparkasse-uelzen.de','Sparkasse_Uelzen@vgh.de'));
  
  spk.push(new spkItem('1548','Kreissparkasse Verden','http://www.ksk-verden.de','service@ksk-verden.de'));
  
  spk.push(new spkItem('1549','Kreissparkasse Walsrode','http://www.ksk-walsrode.de','info@ksk-walsrode.de'));
  
  spk.push(new spkItem('1550','Sparkasse Hameln-Weserbergland','http://www.spkhw.de','info@spkhw.de'));
  
  spk.push(new spkItem('1551','Weser-Elbe Sparkasse','http://www.wespa.de','versicherungscenter@wespa.de'));
  
  spk.push(new spkItem('1554','Stadtsparkasse Wunstorf','http://www.ssk-wunstorf.de','Stspk_Wunstorf@vgh.de'));
  
  spk.push(new spkItem('1555','Weser-Elbe Sparkasse','http://www.wespa.de','versicherungscenter@wespa.de'));
  
  spk.push(new spkItem('1556','Bremer Landesbank','http://www.bremerlandesbank.de','kontakt@bremerlandesbank.de'));
  
  spk.push(new spkItem('1666','Test OEV!','http://www.oev-online.de','mjourlova@oev.de'));
  
  spk.push(new spkItem('1701','Test VGH!','http://www.vgh.de','hans-juergen.ullrich@vgh.de'));
  
  spk.push(new spkItem('1702','Test VGH!','http://www.vgh.de','konstantin.bruchmueller@vgh.de'));
  
  spk.push(new spkItem('9999','Test NERDZZ!','http://www.vgh.de','t.bleeken@nerdzz.digital'));


  return spk;
};

export default useSparkasse;