import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { RefObject, useCallback, useEffect, useRef } from 'react';

const useBodyScrollLock = ({
  targetElement,
  isLocked,
}: {
  targetElement: RefObject<HTMLElement | Element>;
  isLocked: boolean;
}) => {
  const disabledTargetRef = useRef<HTMLElement | Element | null>(null);

  const disableScroll = useCallback(() => {
    if (!targetElement.current) return;
    disableBodyScroll(targetElement.current, {
      reserveScrollBarGap: true,
      allowTouchMove: el => targetElement.current?.contains(el as Node),
    });
    disabledTargetRef.current = targetElement.current;
  }, [targetElement]);

  const reEnableScroll = useCallback(() => {
    if (!disabledTargetRef.current) return;
    enableBodyScroll(disabledTargetRef.current);
  }, [disabledTargetRef]);

  useEffect(() => {
    if (isLocked) {
      disableScroll();
    } else {
      reEnableScroll();
    }
    return () => reEnableScroll();
  }, [disableScroll, isLocked, reEnableScroll]);
};

export default useBodyScrollLock;
